


*
{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
  
  overflow-x: hidden;
  font-family: 'Lato', sans-serif;
}
.bordered-bottom
{
  border-bottom: 3px solid black;
  padding: 0 0 1.5vh .5vw;
  width:fit-content;
}
.montserat
{
  font-family: 'Montserrat', sans-serif !important;
}
.nunito
{
  font-family: 'Nunito', sans-serif !important;
}
.unbounded
{
  font-family: 'Unbounded', sans-serif;
}
.hanno
{
  font-family: 'Hanno Test', sans-serif;
                                                
}
.white-button
{
  background-color: white;
  color: black;
  border-radius: 30px;
  padding: 1.5vh 2vw;
  font-family: 'Nunito', sans-serif !important;
  width: fit-content;
  font-weight: bold;
  text-decoration: none;
  outline: none;
  border: 2px solid white;
}
.form-btn {
  padding: 10px 15px;
  font-family: 'Nunito', sans-serif !important;
  border-radius: 20px;
  font-weight: bolder;
  border: 2px solid #FF6300 !important;
  outline: 0 !important;
  background: #FF6300;
  color: white;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  
  transition: .4s;
}
.form-btn:hover
{
    border: 2px solid #FF6300 !important;
    background-color: white;
    color: black !important;
    font-weight: bold !important;
}
