.side-bar
{
    background-color: black;
    height: 100vh;
    color: white;
    border-radius: 0 25px 25px 0;
    
    position: fixed;
    left: 0;
}
.sidebar-links 
{
    color: #d4d4d4;
    text-decoration: none;
    transition: .4s;
    margin: 1.4vh 0  1.4vh 0;
    font-weight: bold;
    font-family: 'Nunito', sans-serif !important
}
.sidebar-links:hover
{
    color: white;
}
.sidebar-links.active {
    color: black;
    background-color: white;
    border-radius: 10px 0 0 10px; /* Adjust border-radius for the active state */
    padding: 1vh 1.5vw;
    margin-right: -1vw;
}
.sidebar-links.active:hover
{
    color: #525252;
}
.bottom-sidebar
{
    border-top: #525252;
}


.bottom-sidebar .copyright
{
    color: #7c7c7c;
}

.navbar-sm-container
{
    display: none;
}
@media screen and (max-width: 1200px) 
{
    .navbar-sm-container
    {
        display: block;
    }  
    .side-bar
    {
      display: none !important;
    }  
}
.navbar-sm
{
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2vh;
}

/* Header.css */

body {
    font-family: "Lato", sans-serif;
    transition: background-color .5s;
  }
  
  .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    background-color: black;
    overflow-x: hidden;
    transition: width 0.5s; /* Add transition property */
    padding-top: 60px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 16px;
    color: white;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  #main {
    transition: margin-left .5s;
    padding: 16px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }
  
  .open {
    width: 270px;
  }
  
  .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
  