.content
{
        transition: margin-left .5s;
}

.other-side
{
    margin-left: 16.66666667%;
}
@media screen and (max-width: 1200px) 
{
    .other-side
    {
        margin-left: 0 !important;
        width: 100%;
    }    
}
.card {
    width: 100%;
    color: white;
    min-height: 35vh;
    padding: 4vh 3vw;
}
.withdrawal.card {
    

    --s: 37px; /* control the size */
  
    --c: #0000, #282828 0.5deg 119.5deg, #0000 120deg;
    --g1: conic-gradient(from 60deg at 56.25% calc(425% / 6), var(--c));
    --g2: conic-gradient(from 180deg at 43.75% calc(425% / 6), var(--c));
    --g3: conic-gradient(from -60deg at 50% calc(175% / 12), var(--c));
    background: var(--g1), var(--g1) var(--s) calc(1.73 * var(--s)), var(--g2),
      var(--g2) var(--s) calc(1.73 * var(--s)), var(--g3) var(--s) 0,
      var(--g3) 0 calc(1.73 * var(--s)) #1e1e1e;
    background-size: calc(2 * var(--s)) calc(3.46 * var(--s));
  }
  
.credit-card.card {

    --s: 140px; /* control the size */
  
    --_g: #0000 52%, #170409 /* first color */ 54% 57%, #0000 59%;
    background: radial-gradient(farthest-side at -33.33% 50%, var(--_g)) 0
        calc(var(--s) / 2),
      radial-gradient(farthest-side at 50% 133.33%, var(--_g)) calc(var(--s) / 2)
        0,
      radial-gradient(farthest-side at 133.33% 50%, var(--_g)),
      radial-gradient(farthest-side at 50% -33.33%, var(--_g)), #1e3127; /* second color */
    background-size: calc(var(--s) / 4.667) var(--s),
      var(--s) calc(var(--s) / 4.667);

      /* OR DIFFERENT STYLE 
        width: 100%;
  height: 100%;
  /* Add your background pattern here 
  background-image: repeating-radial-gradient(  #0c0a0a 80%,#2f312f 90%,#3f4549 90%);
  background-size: 65px 65px;
      */
  }
  
.refund-set.card {
    
    --color: rgba(25, 46, 28, 0.753);
    background-color: #191a1a;
    background-image: linear-gradient(
        180deg,
        transparent 24%,
        var(--color) 25%,
        var(--color) 26%,
        transparent 27%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        45deg,
        transparent 24%,
        var(--color) 35%,
        var(--color) 26%,
        transparent 17%,
        transparent 74%,
        var(--color) 75%,
        var(--color) 76%,
        transparent 77%,
        transparent
      );
    background-size: 55px 55px;
  }
  